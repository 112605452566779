<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contatti</li>
            </ul>
            <h2>Contattaci</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div id="map">
    <iframe src="https://maps.google.com/maps?q=corso%20Alcide%20De%20Gasperi%2089%20Castellammare%20di%20Stabia%20Italia&t=k&z=19&ie=UTF8&iwloc=&output=embed"></iframe>
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row mb-5">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Chi siamo</span>
                    <h2>Il Migliore Ente di Formazione Accreditato e Certificato</h2>
                    <p>
                        è un Ente riconosciuto dalla Regione Campania (Codice Accreditamento 2562/11/15) che opera da diversi decenni nei settori della Formazione, dell'Istruzione e dell'Università.
                    </p>
                    <p>
                        L’Offerta Formativa, da sempre in costante aggiornamento, mira a diversificare gli ambiti d'interesse proponendo la migliore selezione di corsi finanziati e autofinanziati maggiormente spendibili sul territorio e fruibili sia nella modalità in presenza che on-line.
                    </p>
                    <p>
                        Dall’Estetica e Acconciatura al settore Socio-Sanitario; dall’ambito Tecnico-Amministrativo al Recupero anni scolastici; dai corsi di Laurea e di Perfezionamento ai Master e ai 24 Cfu; dalle Certificazioni linguistiche a quelle Informatiche; ogni percorso è strutturato secondo i migliori standard qualitativi ed è gestito da un corpo docente e da personale tecnico interno ed esterno puntualmente disponibile ed altamente qualificato.
                    </p>
                    <p>
                        Le sedi sono generalmente ben servite da Autobus e Pullman di linea, Circumvesuviana e Ferrovie dello Stato. Su richiesta, però, è anche disponibile un servizio di scuolabus privato.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">dalla formazione alla professione</span>
                    <h2>Cosa abbiamo di Speciale?</h2>
                    <p>
                        Ognuna delle tre sedi scolastiche dell’Istituto San Marco rispetta pienamente i canoni di idoneità, salubrità e sicurezza. La sede centrale di Castellammare di Stabia in via A. De Gasperi 89 con affaccio sul lungomare e vista mozzafiato sul golfo di Napoli, è tra gli edifici scolastici di epoca moderna più rappresentativi della provincia partenopea ed è punto di riferimento per gli studenti della penisola sorrentina, dei monti lattari e dei paesi vesuviani come Pompei, Boscoreale e Torre Annunziata. Questa, oltre ad essere polo dell’Università Telematica e-Campus, è anche sede sindacale CONFAL per la consulenza del personale della scuola.
                    </p>
                    <p>
                        La succursale storica di Arzano in via Pecchia 170 e quella più recente di Giugliano in via San Francesco d’Assisi 16, invece, servono comodamente la platea scolastica residente nell’area Nord del comune di Napoli fino a Villaricca.
                    </p>
                    <p>
                        Il personale di segreteria, operativo dal lunedì al venerdì dalle 9:00 alle 18:00 in sede o telefonicamente al numero di centralino +39 081.8701052, è sempre pronto a fornire al Cliente il migliore supporto informativo e a seguirlo sia in fase di produzione della documentazione che in fase di iscrizione e negli adempimenti amministrativi.


                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Informazioni</span>
                    <h2>Mettiti in contatto</h2>
                    <p>Ci piace ascoltare i lettori. Apprezziamo davvero che tu abbia dedicato del tempo per metterci in contatto.</p>
                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Dove siamo</h3>
                            <p>corso Alcide De Gasperi,89<br>80053 Castellammare di Stabia (NA)</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>Contatti</h3>
                            <p>Mobile: <a href="tel:+390818701052">(+39) 081-8701052</a></p>
                            <p>e-mail: <a href="mailto:elearning@formazionesanmarco.it">elearning@formazionesanmarco.it</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h3>Siamo disponibili</h3>
                            <p>Lunedì - Venerdì: 09:00 - 18:00</p>
                            <p>Sabato: 09:00 - 13:00</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
                    <h2>Come possiamo aiutarti?</h2>
                    <p>L'indirizzo email non verrà pubblicato. i campi richiesti sono contrassegnati dal simbolo*</p>
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Nome e Cognome">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Nome e Cognome richiesto.</div>
                                        <div *ngIf="name.errors.minlength">Numero minimo {{ name.errors.minlength.requiredLength }} di caratteri.</div>
                                        <div *ngIf="name.errors.maxlength">Numero massimo di 50 caratteri.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="la tua e-mail">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="cellulare">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">un contatto telefonico è obbligatorio</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5" class="form-control" placeholder="scrivi il tuo messaggio..."></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">il messaggio è obbligatorio</div>
                                </div>
                            </div>
                            <h2 class="mt-3">Quale corso sei interessato?</h2>
                            <p>seleziona il corso interessato alla quale vuoi avere informazioni*</p>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <select name="sceltaCorso" ngModel id="sceltaCorso" #sceltaCorso="ngModel" style="display: block;
                                    width: 100%;
                                    border: none;
                                    color: var(--blackColor);
                                    background-color: #F3F6F9;
                                    height: 50px;
                                    padding-left: 15px;
                                    border-radius: 5px;
                                    font-size: var(--fontSize);
                                    font-weight: 600">
                                        <option value="{{elencoCorsiRow['corso']['titolo']}}" *ngFor="let elencoCorsiRow of elencoCorsi">{{elencoCorsiRow['corso']['titolo']}}</option>
                                      </select>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 mt-3">
                                <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid">Invia Richiesta<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
